import { createSlice, CreateSliceOptions, SliceCaseReducers } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  subTitle: '',
  keepParentLayout: true,
  enableReturn: false,
};

const titleSlice = {
  name: 'header',
  initialState,
  reducers: {
    setHeader: (state, { payload }) => {
      state.title = payload.title;
      state.subTitle = payload.subTitle;
      if (payload.keepParentLayout !== undefined) {
        state.keepParentLayout = payload.keepParentLayout;
      }
      state.enableReturn = payload.enableReturn;
    },
    reset: () => {
      return initialState;
    },
  },
};

const { reducer, actions } = createSlice(titleSlice);
export const { setHeader, reset } = actions;
export default reducer;
