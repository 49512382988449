import { ReactComponent as NothingFoundDark } from './nothing_found_dark.svg';
import { ReactComponent as NothingFoundLight } from './nothing_found_light.svg';

const Illustrations = {
  NothingFound: ({ variant, ...props }) =>
    variant === 'dark' ? (
      <NothingFoundDark {...props} />
    ) : (
      <NothingFoundLight {...props} />
    ),
};

export default Illustrations;
