import React from 'react';
import Skelton from '../index';
const CreateEntitySkeleton = () => {
  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="basic-form">
            <div className="form-valide">
              <div className="row">
                {/* Title Input Skeleton */}
                <div className="form-group mb-3 row">
                  <Skelton height={50} style={{ borderRadius: '4px' }} />
                </div>

                {/* Data Type Dropdown Skeleton */}
                <div className="form-group mb-3 row">
                  <Skelton height={50} style={{ borderRadius: '4px' }} />
                </div>

                {/* Description TextArea Skeleton */}
                <div className="form-group mb-3 row">
                  <Skelton height={300} style={{ borderRadius: '4px' }} />
                </div>

                {/* Buttons Skeleton */}
                <div className="form-group mb-3 row">
                  <div className="col-lg-8 d-flex justify-content-end w-100 gap-2">
                    <Skelton
                      height={40}
                      width={100}
                      style={{ borderRadius: '4px', marginRight: '1rem' }}
                    />
                    <Skelton height={40} width={100} style={{ borderRadius: '4px' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEntitySkeleton;
