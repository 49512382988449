import { ReactComponent as ActionIcon } from './icons/action-icons.svg';
import { ReactComponent as ArrowDownCircleIcon } from './icons/arrow-down-circle.svg';
import { ReactComponent as BotsIcon } from './icons/bots-icon.svg';
import { ReactComponent as BriefcaseIcon } from './icons/briefcase-icon.svg';
import { ReactComponent as CalendarIcon } from './icons/calendar-icon.svg';
import { ReactComponent as CallLogsIcon } from './icons/call-logs-icon.svg';
import { ReactComponent as CardArrowLeft } from './icons/card-arrow-left.svg';
import { ReactComponent as CartAddIcon } from './icons/cart-add.svg';
import { ReactComponent as CheckCircleIcon } from './icons/check-circle.svg';
import { ReactComponent as CheckboxIcon } from './icons/checkbox.svg';
import { ReactComponent as ChevronUpIcon } from './icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from './icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from './icons/chevron-right.svg';
import { ReactComponent as ChevronDownIcon } from './icons/chevron-up.svg';
import { ReactComponent as ClockIcon } from './icons/clock-icon.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as CompletedIcon } from './icons/completed.svg';
import { ReactComponent as ConversationIcon } from './icons/conversation-svgrepo-com.svg';
import { ReactComponent as CopyIcon } from './icons/copy-icon.svg';
import { ReactComponent as CorrectIcon } from './icons/correct.svg';
import { ReactComponent as CostIcon } from './icons/cost-icon.svg';
import { ReactComponent as DangerIcon } from './icons/danger.svg';
import { ReactComponent as DashboardIcon } from './icons/dashboard-icon.svg';
import { ReactComponent as DispatchedIcon } from './icons/dispatched.svg';
import { ReactComponent as DollarIcon } from './icons/dollar-icon.svg';
import { ReactComponent as DownloadIcon } from './icons/download-icon.svg';
import { ReactComponent as DraggableIcon } from './icons/draggable-icon.svg';
import { ReactComponent as EmailNotFound } from './icons/email-not-found.svg';
import { ReactComponent as ExchangeOutline } from './icons/exchange-rectangle.svg';
import { ReactComponent as EyeOffIcon } from './icons/eye-off.svg';
import { ReactComponent as EyeOnIcon } from './icons/eye-on.svg';
import { ReactComponent as FileDownloadIcon } from './icons/file-download.svg';
import { ReactComponent as FileUploadIcon } from './icons/file-upload-icon.svg';
import { ReactComponent as FilterIcon } from './icons/filter.svg';
import { ReactComponent as DataStoreIcon } from './icons/grid-view.svg';
import { ReactComponent as GridViewIcon } from './icons/grid-view.svg';
import { ReactComponent as HomeIcon } from './icons/home-icon.svg';
import { ReactComponent as ImageCircleIcon } from './icons/image-circle.svg';
import { ReactComponent as IndustryIcon } from './icons/industry-icon.svg';
import { ReactComponent as InfoFilled } from './icons/info-filled-icon.svg';
import { ReactComponent as InfoOutline } from './icons/info-outline-icon.svg';
import { ReactComponent as InteractionIcon } from './icons/interactions-icon.svg';
import { ReactComponent as InviteMailIcon } from './icons/invite-mail-icon.svg';
import { ReactComponent as LeftArrowIcon } from './icons/left-arrow.svg';
import { ReactComponent as LikeIcon } from './icons/like.svg';
import { ReactComponent as ListViewIcon } from './icons/list-view.svg';
import { ReactComponent as Loader } from './icons/loading.svg';
import { ReactComponent as MailIcon } from './icons/mail-icon.svg';
import { ReactComponent as MailOutlineIcon } from './icons/mail-outline-icon.svg';
import { ReactComponent as MarketplaceIcon } from './icons/marketplace-icon.svg';
import { ReactComponent as MaximizeIcon } from './icons/maximize-icon.svg';
import { ReactComponent as MicIcon } from './icons/mic.svg';
import { ReactComponent as MicOutlineIcon } from './icons/mic-outline-icon.svg';
import { ReactComponent as PauseIcon } from './icons/pause.svg';
import { ReactComponent as PencilOutline } from './icons/pencil-icon.svg';
import { ReactComponent as PlayIcon } from './icons/play-icon.svg';
import { ReactComponent as PlusIcon } from './icons/plus-icon.svg';
import { ReactComponent as PulseRingAnimatedIcon } from './icons/pulse-ring.svg';
import { ReactComponent as Reload } from './icons/reload-icon.svg';
import { ReactComponent as RightArrowIcon } from './icons/right-arrow.svg';
import { ReactComponent as RunsCountIcon } from './icons/runs-count-icon.svg';
import { ReactComponent as ScheduledIcon } from './icons/scheduled.svg';
import { ReactComponent as SearchIcon } from './icons/search-icon.svg';
import { ReactComponent as SendInviteIcon } from './icons/send-invite-icon.svg';
import { ReactComponent as SettingsIcon } from './icons/settings-icon.svg';
import { ReactComponent as SortAscending } from './icons/sort-arrow-down.svg';
import { ReactComponent as SortDescending } from './icons/sort-arrow-up.svg';
import { ReactComponent as StarFilledIcon } from './icons/star-filled.svg';
import { ReactComponent as StarOutlineIcon } from './icons/star-full.svg';
import { ReactComponent as StarHalfOutlineIcon } from './icons/star-half.svg';
import { ReactComponent as StarHalfFilledIcon } from './icons/star-half-filled.svg';
import { ReactComponent as TotalJobsIcon } from './icons/total-jobs-icon.svg';
import { ReactComponent as TradesIcon } from './icons/trades-icon.svg';
import { ReactComponent as TransferIcon } from './icons/transfer-icon.svg';
import { ReactComponent as TrashIcon } from './icons/trash-icon.svg';
import { ReactComponent as UserAdd } from './icons/user-add.svg';
import { ReactComponent as UserCircleAdd } from './icons/user-circle-add.svg';
import { ReactComponent as UserIcon } from './icons/user-icon.svg';
import { ReactComponent as UserPolygonIcon } from './icons/user-polygon.svg';
import { ReactComponent as Vector } from './icons/Vector.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';

export const Icons = {
  DashboardIcon: ({ ...props }) => <DashboardIcon {...props} />,
  DataStoreIcon: ({ ...props }) => <DataStoreIcon {...props} />,
  ConversationIcon: ({ ...props }) => <ConversationIcon {...props} />,
  ActionIcon: ({ ...props }) => <ActionIcon {...props} />,
  BotsIcon: ({ ...props }) => <BotsIcon {...props} />,
  SettingsIcon: ({ ...props }) => <SettingsIcon {...props} />,
  UserIcon: ({ ...props }) => <UserIcon {...props} />,
  InteractionIcon: ({ ...props }) => <InteractionIcon {...props} />,
  MarketplaceIcon: ({ ...props }) => <MarketplaceIcon {...props} />,
  ChevronLeft: ({ ...props }) => <ChevronLeft {...props} />,
  ChevronRight: ({ ...props }) => <ChevronRight {...props} />,
  CheckboxIcon: ({ ...props }) => <CheckboxIcon {...props} />,
  CorrectIcon: ({ ...props }) => <CorrectIcon {...props} />,
  PauseIcon: ({ ...props }) => <PauseIcon {...props} />,
  MicIcon: ({ ...props }) => <MicIcon {...props} />,
  MicOutlineIcon: ({ ...props }) => <MicOutlineIcon {...props} />,
  DangerIcon: ({ ...props }) => <DangerIcon {...props} />,
  CompletedIcon: ({ ...props }) => <CompletedIcon {...props} />,
  DispatchedIcon: ({ ...props }) => <DispatchedIcon {...props} />,
  ScheduledIcon: ({ ...props }) => <ScheduledIcon {...props} />,
  SearchIcon: ({ ...props }) => <SearchIcon {...props} />,
  CloseIcon: ({ ...props }) => <CloseIcon {...props} />,
  EyeOnIcon: ({ ...props }) => <EyeOnIcon {...props} />,
  EyeOffIcon: ({ ...props }) => <EyeOffIcon {...props} />,
  ChevronUp: ({ ...props }) => <ChevronUpIcon {...props} />,
  ChevronDown: ({ ...props }) => <ChevronDownIcon {...props} />,
  MailIcon: ({ ...props }) => <MailIcon {...props} />,
  InviteMailIcon: ({ ...props }) => <InviteMailIcon {...props} />,
  FileUploadIcon: ({ ...props }) => <FileUploadIcon {...props} />,
  DraggableIcon: ({ ...props }) => <DraggableIcon {...props} />,
  CopyIcon: ({ ...props }) => <CopyIcon {...props} />,
  SortAscending: ({ ...props }) => <SortAscending {...props} />,
  SortDescending: ({ ...props }) => <SortDescending {...props} />,
  FilterIcon: ({ ...props }) => <FilterIcon {...props} />,
  StarHalfOutlineIcon: ({ ...props }) => <StarHalfOutlineIcon {...props} />,
  StarOutlineIcon: ({ ...props }) => <StarOutlineIcon {...props} />,
  StarHalfFilledIcon: ({ ...props }) => <StarHalfFilledIcon {...props} />,
  StarFilledIcon: ({ ...props }) => <StarFilledIcon {...props} />,
  DollarIcon: ({ ...props }) => <DollarIcon {...props} />,
  TransferIcon: ({ ...props }) => <TransferIcon {...props} />,
  ArrowDownCircleIcon: ({ ...props }) => <ArrowDownCircleIcon {...props} />,
  InfoFilled: ({ ...props }) => <InfoFilled {...props} />,
  FileDownloadIcon: ({ ...props }) => <FileDownloadIcon {...props} />,
  InfoOutline: ({ ...props }) => <InfoOutline {...props} />,
  TrashIcon: ({ ...props }) => <TrashIcon {...props} />,
  RightArrowIcon: ({ ...props }) => <RightArrowIcon {...props} />,
  HomeIcon: ({ ...props }) => <HomeIcon {...props} />,
  SendInviteIcon: ({ ...props }) => <SendInviteIcon {...props} />,
  GridViewIcon: ({ ...props }) => <GridViewIcon {...props} />,
  ListViewIcon: ({ ...props }) => <ListViewIcon {...props} />,
  WarningIcon: ({ ...props }) => <WarningIcon {...props} />,
  ClockIcon: ({ ...props }) => <ClockIcon {...props} />,
  LeftArrowIcon: ({ ...props }) => <LeftArrowIcon {...props} />,
  CalendarIcon: ({ ...props }) => <CalendarIcon {...props} />,
  TotalJobsIcons: ({ ...props }) => <TotalJobsIcon {...props} />,
  DownloadIcon: ({ ...props }) => <DownloadIcon {...props} />,
  CardArrowLeft: ({ ...props }) => <CardArrowLeft {...props} />,
  PlayIcon: ({ ...props }) => <PlayIcon {...props} />,
  EmailNotFound: ({ ...props }) => <EmailNotFound {...props} />,
  BriefcaseIcon: ({ ...props }) => <BriefcaseIcon {...props} />,
  CallLogsIcon: ({ ...props }) => <CallLogsIcon {...props} />,
  Vector: ({ ...props }) => <Vector {...props} />,
  Maximize: ({ ...props }) => <MaximizeIcon {...props} />,
  Plus: ({ ...props }) => <PlusIcon {...props} />,
  CartAdd: ({ ...props }) => <CartAddIcon {...props} />,
  CheckCircle: ({ ...props }) => <CheckCircleIcon {...props} />,
  MailOutline: ({ ...props }) => <MailOutlineIcon {...props} />,
  UserAdd: ({ ...props }) => <UserAdd {...props} />,
  UserCircleAdd: ({ ...props }) => <UserCircleAdd {...props} />,
  UserPolygonIcon: ({ ...props }) => <UserPolygonIcon {...props} />,
  ExchangeOutline: ({ ...props }) => <ExchangeOutline {...props} />,
  PlusIcon: ({ ...props }) => <PlusIcon {...props} />,
  Reload: ({ ...props }) => <Reload {...props} />,
  PencilOutline: ({ ...props }) => <PencilOutline {...props} />,
  Loader: ({ ...props }) => <Loader {...props} />,
  LikeIcon: ({ ...props }) => <LikeIcon {...props} />,
  CostIcon: ({ ...props }) => <CostIcon {...props} />,
  IndustryIcon: ({ ...props }) => <IndustryIcon {...props} />,
  RunsCountIcon: ({ ...props }) => <RunsCountIcon {...props} />,
  TradesIcon: ({ ...props }) => <TradesIcon {...props} />,
  ImageCircleIcon: ({ ...props }) => <ImageCircleIcon {...props} />,
  PulseRingAnimatedIcon: ({ ...props }) => <PulseRingAnimatedIcon {...props} />,
};
