import { ReactComponent as LoadingIcon } from '../../../assets/icons/loading.svg';
import { cva, VariantProps } from 'class-variance-authority';

export const buttonVariants = cva(
  'flex gap-2 items-center justify-center w-fit h-fit rounded-md font-bold  disabled:cursor-not-allowed whitespace-nowrap',
  {
    variants: {
      variant: {
        primary:
          'bg-greyscale-900 text-greyscale-100 fill-greyscale-100 dark:bg-greyscale-100 dark:text-greyscale-900 dark:fill-greyscale-900 disabled:dark:text-greyscale-200 disabled:bg-greyscale-300 disabled:text-greyscale-0',
        secondary:
          'ring-1 ring-greyscale-900 text-greyscale-900  dark:ring-greyscale-100 dark:text-greyscale-100 disabled:text-greyscale-300  disabled:dark:text-greyscale-600 disabled:ring-greyscale-200  disabled:dark:ring-greyscale-600 ',
        enable: 'text-greyscale-500  dark:text-white',
      },
      size: {
        sm: 'px-6 py-2 text-xs',
        md: 'px-6 py-3 text-sm',
        lg: 'px-6 py-4 text-base',
      },
      defaultVariants: {
        size: 'lg',
        variant: 'primary',
      },
    },
  },
);

import { forwardRef } from 'react';
import classNames from 'classnames';

const Button = forwardRef(
  (
    { className, isLoading, iconLeft, iconRight, variant, size, children, ...props },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        className={classNames(buttonVariants({ variant, size }), className)}
        {...props}
      >
        {isLoading && (
          <LoadingIcon className="w-5 h-5 mr-2 animate-spin fill-primary border-t-transparent dark:stroke-gray-600" />
        )}
        {!isLoading && iconLeft}
        {children}
        {iconRight}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
