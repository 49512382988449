import API from 'src/lib/api-client';
import { useMutation } from '@tanstack/react-query';

const chatResponse = async (values) => {
  const url = `/api/public/chat/`;
  const response = await API('post', url, values);
  if (!response) {
    return false;
  }
  return await response?.data?.data;
};

export function useChatMutation() {
  const {
    mutateAsync: ChatMutation,
    isLoading,
    error,
    data,
    isSuccess,
  } = useMutation((values) => chatResponse(values));

  return {
    ChatMutation,
    isLoading,
    error: (error && error?.data?.message) || '',
    data,
    isSuccess,
  };
}
