import useTitle from '../../../hooks/useTitle';
import { Input, TextArea } from 'src/components/ui-components';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getAgentDetail, useCreateEntity } from './services';
import { DataTypes } from '../listing/helpers';
import DropdownBootstrap from '../../../components/ui-components/dropdown-Input';
import CreateEntitySkeleton from '../../../components/ui-components/skeleton/entities-create';
const CreateEntity = () => {
  useTitle('Create Entity');
  const { createEntityMutation, data, isLoading } = useCreateEntity();
  const { id } = useParams();
  const navigate = useNavigate();
  const [entityDetailLoading, setEntityDetailLoading] = useState(false);

  const {
    control,
    handleSubmit,
    trigger,
    //getValues,
    setValue,
    //watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    values.entityId = id;

    const updatedData = {
      ...values,
      data_type: values.data_type?.label, // Extract the title from the first item in data_type
    };
    createEntityMutation(updatedData);
  };
  const setEntityDetail = async (id) => {
    const response = await getAgentDetail(id);

    setValue('title', response?.title);
    trigger('title');

    setValue('description', response?.description);
    trigger('description');
    const dataType = { value: response.data_type, label: response.data_type };
    setValue('data_type', dataType);
    trigger('data_type');
    setEntityDetailLoading(false);
  };
  useEffect(() => {
    if (id) {
      setEntityDetail(id);
      setEntityDetailLoading(true);
    }
  }, [id]);
  useEffect(() => {
    if (data) {
      if (data?.status) {
        navigate(-1);
      } else {
        toast.error('error');
      }
    }
  }, [data]);

  return (
    <>
      {entityDetailLoading ? (
        <CreateEntitySkeleton />
      ) : (
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <div className="form-valide">
                  <div className="row">
                    <div className="form-group mb-3 row">
                      <Controller
                        name="title"
                        control={control}
                        rules={{
                          validate: {
                            require: (value) => {
                              if (!value?.trim()) return 'Required';
                            },
                          },
                          required: true,
                        }}
                        render={({ field: { name, onChange, value } }) => (
                          <Input
                            name={name}
                            label="Title"
                            required
                            placeholder="Title"
                            onChange={onChange}
                            error={errors && !value?.trim() ? errors.title : undefined}
                            value={value ? value : ''}
                          />
                        )}
                      />
                    </div>
                    <div className="form-group mb-3 row">
                      <Controller
                        name="data_type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <DropdownBootstrap
                            onChange={onChange}
                            value={value}
                            items={DataTypes}
                            label="Type"
                            error={!!errors?.data_type && !value}
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="form-group mb-3 row">
                      <Controller
                        name="description"
                        control={control}
                        rules={{
                          required: true,
                          validate: {
                            require: (value) => {
                              if (!value?.trim()) return 'Required';
                            },
                          },
                        }}
                        render={({ field: { name, onChange, value } }) => (
                          <TextArea
                            name={name}
                            label="Description"
                            placeholder="Description"
                            value={value ? value : ''}
                            onChange={onChange}
                            rows={10}
                            required
                            error={
                              errors && !value?.trim() ? errors.description : undefined
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="form-group mb-3 row">
                      <div className="col-lg-8 d-flex justify-content-end  w-100">
                        <button
                          onClick={() => navigate(-1)}
                          type="button"
                          className="btn btn-outline-primary me-4"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleSubmit(onSubmit)}
                          type="submit"
                          className="btn btn-primary"
                          disabled={isLoading}
                        >
                          Save
                          {isLoading ? (
                            <span
                              role="status"
                              className="spinner-border spinner-border-sm ms-1"
                            />
                          ) : (
                            <span className="btn-icon-right ms-1">
                              <i className="fa fa-check" />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateEntity;
