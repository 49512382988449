import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { cva, VariantProps } from 'class-variance-authority';
import classNames from 'classnames';

const alertVariants = cva('flex justify-between items-center border px-3 py-3', {
  variants: {
    variant: {
      error: 'bg-alert-error-light border-alert-error-base text-alert-error-base',
      info: 'bg-primary-200 border-primary text-primary',
      success: '',
    },
    size: {
      sm: '',
      lg: '',
    },
  },
});

const Alert = ({ type = 'success', children, text, className, clearAlert, ...props }) => {
  let alertClass = '';
  switch (type) {
    case 'error':
      alertClass = 'bg-[#FEE4E2] border-[#F04438] text-[#F04438] dark:bg-transparent';
      break;
    case 'info':
      alertClass = 'bg-[#E9F1F9] border-[#038EFD] text-[#038EFD] dark:bg-transparent';
      break;
    case 'success':
    default:
      alertClass = 'bg-[#E6FBEB] border-[#02D42E] text-[#02D42E] dark:bg-transparent';
      break;
  }

  return (
    <div
      className={classNames(
        `rounded relative mb-[1.25rem] flex justify-between items-center border ${alertClass} px-3 py-3`,
        className || '',
      )}
      role="alert"
      {...props}
    >
      {children ? children : <span className="block sm:inline">{text}</span>}

      {clearAlert && (
        <Close
          className="dark:fill-gray-500 cursor-pointer transition-all fill-[#8F8F8F]"
          onClick={clearAlert}
        />
      )}
    </div>
  );
};

export default Alert;
