import classNames from 'classnames';
const styles = `
  @keyframes pulse {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

// Append the keyframes to the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default function Skelton({ type, width = '', height, className }) {
  const singleLine = (lineWidth = width, lineHeight = height, SingleLineClass = '') => (
    <div
      style={{
        background: 'linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%)',
        width: lineWidth ? `${lineWidth}px` : '100%',
        height: lineHeight ? `${lineHeight}px` : '100%',
        borderRadius: SingleLineClass.includes('rounded-xl') ? '12px' : '4px',
        animation: 'pulse 1.5s infinite',
      }}
      className={classNames('animate-pulse', {
        [`${SingleLineClass}`]: SingleLineClass,
      })}
    />
  );

  switch (type) {
    case 'integration-card':
      return (
        <div
          style={{
            background: 'grey',
            padding: '24px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '16px',
            }}
          >
            {singleLine(14, 14, '!rounded-xl')}
            {singleLine('', Number(height) * 4, '!w-32')}
          </div>
          {singleLine(28, 4)}
          {singleLine(44, height, '!mb-2')}
        </div>
      );
    case 'single':
    default:
      return singleLine(width, height, className);
  }
}
