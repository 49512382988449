import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
/// React router dom
import { Link } from 'react-router-dom';
/// images
import logo from '../../../images/favicon.png';
import { toggleSideMenu } from '../../../store/Slices/AuthSlice';

const NavHader = () => {
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.auth.isSideMenuVisible);
  const handleToogle = () => {
    dispatch(toggleSideMenu());
  };
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        <img className="logo-abbr" src={logo} alt="" />
        <h1
          style={{
            color: 'white',
            marginLeft: '4px',
          }}
          className="brand-title"
        >
          Loopify
        </h1>

        {/* <img className="logo-compact" src={logoText} alt="" />
        <img className="brand-title" src={logoText} alt="" /> */}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          handleToogle();
        }}
      >
        <div className={`hamburger ${sideMenu ? 'is-active' : ''}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
