import { Icons } from 'src/assets';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const InfoButton = ({ className, info, ...props }) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={classNames(className)}
      {...props}
    >
      <OverlayTrigger
        trigger="hover"
        placement="right"
        overlay={
          <Tooltip className="toltip-popover" id={`popover-positioned-bottom`}>
            {info}
          </Tooltip>
        }
        responsive={true}
      >
        <button className="btn-outline-light border-0 bg-transparent">
          <Icons.InfoOutline className="text-muted w-100 h-auto" />
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default InfoButton;
