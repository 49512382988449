import './css/style.css';
import './scss/main.scss';
// import './index.css';
import { Icons } from './assets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import * as ReactDom from 'react-dom';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-toastify';
import { Provider } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import Favicon from './components/ui-components/favicon';
// import { store } from './lib/create-store';
import { store } from './store/store.js';
import Routes from './routes';
import ThemeContextProvider from './store/theme.context.jsx';
import { ToastContainer } from 'react-toastify';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeContextProvider>
            <Favicon />
            <Routes />
            {ReactDom.createPortal(
              <>
                <Tooltip id="info-tooltip" />
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </>,
              document.body,
            )}
            <ReactQueryDevtools position="bottom-right" />
          </ThemeContextProvider>
        </QueryClientProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
);
