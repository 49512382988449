import { createSlice, CreateSliceOptions, SliceCaseReducers } from '@reduxjs/toolkit';

const initialState = [];

const permissionsSlice = {
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (_state, { payload }) => {
      return payload;
    },
  },
};

const { reducer, actions } = createSlice(permissionsSlice);
export const { setPermissions } = actions;
export default reducer;
