import { createContext, useState } from 'react';

export const ChatBoxContext = createContext({});
const ChatBoxProvider = ({ children }) => {
  const [chatVisible, setChatVisible] = useState(false);
  const contextValue = {
    chatVisible,
    setChatVisible,
  };

  return (
    <ChatBoxContext.Provider value={contextValue}>{children}</ChatBoxContext.Provider>
  );
};
export default ChatBoxProvider;
