// import Illustration from 'src/assets/images/illustration.png';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { Login } from 'src/pages';

function AuthLayout(): JSX.Element {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const user = useSelector((state: any) => state.auth);

  useEffect(() => {
    const paymentError = localStorage.getItem('cxc_payment_error_msg');
    if (paymentError) {
      toast.error(paymentError);
      localStorage.removeItem('cxc_payment_error_msg');
    }
  }, []);

  if (user?.isLoggedIn) {
    return (
      <Navigate
        to={searchParams.has('referer') ? String(searchParams.get('referer')) : '/'}
        replace
      />
    );
  }

  return (
    <div>
      <div>{location.pathname === '/' ? <Login /> : <Outlet />}</div>
    </div>
  );
}

export default AuthLayout;
