import useUser from '../../hooks/useUser';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { setUserDetails } from '../../reducers/auth';
import ChatBoxProvider from './chatboxStore';

import Nav from './nav';

function Layout() {
  const auth = useSelector((state) => state.auth);
  const { keepParentLayout } = useSelector((state) => state.title);
  const isSessionExpired = auth.isSessionExpired;
  const sideMenu = useSelector((state) => state.auth.isSideMenuVisible);
  const { userData } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      dispatch(setUserDetails(userData));
    }
  }, [userData, auth?.isLoggedIn, dispatch]);

  return (
    <ChatBoxProvider>
      <div id="main-wrapper" className={`show ${sideMenu ? 'menu-toggle' : ''}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    </ChatBoxProvider>
  );
}

export default Layout;
