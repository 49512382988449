import { lazy } from 'react';
const Datasource = lazy(() => import('./datasource/create'));
const DataSourceList = lazy(() => import('./datasource'));
const DatasourceDetails = lazy(() => import('./datasource/detail'));
const Conversation = lazy(() => import('./converstion'));
const ConversationDetails = lazy(() => import('./converstion/conversationdetail'));
const WebScrapingDetails = lazy(
  () => import('./datasource/create/webscraping/webdetails'),
);
const WebScrapingResultList = lazy(
  () => import('./datasource/create/webscraping/webscraping_result_list'),
);
const Actions = lazy(() => import('./actions'));
const ActionsCreate = lazy(() => import('./actions/create'));

import { AgentCreate, AgentListing, CreateIntent } from './agents';
import { CreateEntity, EntityListing } from './entites';
import { ChangePassword, ForgotPassword, Login, ResetPassword, Signup } from './auth';
import Profile from './auth/profile';
import AuthRedirect from './auth-redirect';
import NoAccessPage from './no-access';

export {
  AgentCreate,
  Actions,
  ActionsCreate,
  AgentListing,
  AuthRedirect,
  ChangePassword,
  Conversation,
  ConversationDetails,
  CreateIntent,
  Datasource,
  DatasourceDetails,
  WebScrapingDetails,
  DataSourceList,
  ForgotPassword,
  Login,
  NoAccessPage,
  Profile,
  ResetPassword,
  Signup,
  CreateEntity,
  EntityListing,
  WebScrapingResultList,
};
