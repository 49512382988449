import { createSlice, CreateSliceOptions, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from 'src/lib/create-store';

const initialState = {
  title: '',
  description: '',
  urls: [],
  files: [],
  articles: [],
};

const dataStoreSlice = {
  name: 'dataStore',
  initialState,
  reducers: {
    setTitle: (state, { payload }) => {
      state.title = payload;
    },
    setDescription: (state, { payload }) => {
      state.description = payload;
    },
    setUrls: (state, { payload }) => {
      state.urls = [
        ...state.urls,
        {
          url: payload,
          is_complete_crawl: false,
        },
      ];
    },
    deleteUrl: (state, { payload }) => {
      state.urls = state.urls.filter(
        (item) =>
          !payload.some(
            (urlObj) =>
              urlObj.url === item.url &&
              urlObj.is_complete_crawl === item.is_complete_crawl,
          ),
      );
    },

    setFiles: (state, { payload }) => {
      state.files = payload;
    },
    setArticles: (state, { payload }) => {
      state.articles = [
        ...state.articles,
        {
          title: payload.title,
          description: payload.description,
        },
      ];
    },
    deleteArticle: (state, { payload }) => {
      state.articles = state.articles.filter((article) => article.title !== payload);
    },
    setDataSource: (state, { payload }) => {
      const { title, description, urls, files, articles } = payload;
      if (title !== undefined) state.title = title;
      if (description !== undefined) state.description = description;
      if (urls !== undefined) state.urls = urls;
      if (files !== undefined) state.files = files;
      if (articles !== undefined) state.articles = articles;
    },
    reset: () => {
      return initialState;
    },
  },
};

const { reducer, actions } = createSlice(dataStoreSlice);
export const {
  setTitle,
  setDescription,
  setUrls,
  setFiles,
  deleteUrl,
  setArticles,
  deleteArticle,
  setDataSource,
  reset,
} = actions;
export const selectDataStore = (state: RootState) => state.dataStore;
export default reducer;
