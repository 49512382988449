import API from 'src/lib/api-client';
import { useMutation } from '@tanstack/react-query';

export const getUserProfile = async () => {
  const url = '/api/user/profile/';
  const response = await API('get', url);
  if (!response) {
    return false;
  }
  return response?.data?.data;
};

const updateProfile = async (values) => {
  const url = `/api/user/profile/`;
  const response = await API('put', url, values);
  if (!response) {
    return false;
  }
  return await response?.data?.data;
};

export function useUpdateProfile() {
  const {
    mutateAsync: UpdateProfileMutation,
    isLoading,
    error,
    data,
    reset,
    isSuccess,
  } = useMutation((values: any) => updateProfile(values));

  return {
    UpdateProfileMutation,
    isLoading,
    error: (error && error?.data?.message) || '',
    data,
    isSuccess,
    reset,
  };
}
