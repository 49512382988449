export const messages = {
  DEFAULT_ERROR_MESSAGE: 'Oops! Something went wrong. Please try again.',
  DEFAULT_TIMEOUT_MESSAGE:
    'Oops!! Request timeout. Please try again after sometime or check your internet connection',

  VALIDATIONS: {
    REQUIRED: 'Required',
    INVALID_VALUE: 'Please enter valid value',
    INVALID_EMAIL_ADDRESS: 'Please enter a valid email address',
    EMAIL_ALREADY_EXISTS: 'Email address is already taken',
    INVALID_URL: 'Please enter a valid url',
    INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
    INVALID_MOBILE_NUMBER: 'Please enter a valid mobile number',
    INVALID_ALPHABET_CHAR: 'Please enter alphabet characters only',
    INVALID_ALPHANUMERIC_CHAR: 'Please enter alpha numeric characters only',
    STRICT_VALID_VALUE: 'Please select a valid value',
    INVALID_NAME: 'Please enter name',
    STRICT_VALID_NAME: 'Please enter a valid name',
    INVALID_USER_NAME: 'Please enter a valid user name',
    INVALID_PASSWORD: 'Please enter the password',
    INVALID_NEW_PASSWORD: 'Please enter new password',
    INVALID_PASSWORD_FORMAT: 'Please enter valid password',
    INVALID_CONFIRM_PASSWORD: 'Please confirm your password',
    PASSWORD_CONF_PASS_UNMATCH: 'Passwords do not match',
    DESCRIPTION_MAXED: 'The maximum length of 1500 characters is reached.',
  },
  PERMISSIONS: {
    ONLY_INTEGRATION_PAGE_ACCESS:
      '• To utilize CxC to its full potential, please connect to a CRM',
  },
  EDIT_INTEGRATION_MSG:
    "Any wrong information entered may cause disruption of sync mechanism or data may be corrupted. We strongly recommend you to double check the values before saving the details for the fields. CxC won't be responsible if data gets corrupted because of wrong information entered.",
  SYNC_PROGRESS_MSG:
    "Your data is currently being synchronized with our system. This process may take a few minutes. You can continue to use the platform, but please do not make any major changes to your data during this time to ensure a smooth sync process. We appreciate your patience. You'll be notified once the synchronization is complete.",
  FETCHING_BOT_LOADING_MSG: 'Fetching Bots results, this might take a few minutes.',
};
