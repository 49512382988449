import API from 'src/lib/api-client';
import { useMutation } from '@tanstack/react-query';

export const getIntentDetail = async (id) => {
  const url = `/api/intent/${id}/`;
  const response = await API('get', url);
  if (!response) {
    return false;
  }
  return response?.data?.data;
};

export const getEntityList = async () => {
  const url = `/api/entities/`;
  const response = await API('get', url);
  if (!response) {
    return false;
  }
  return response?.data?.data;
};

const createIntent = async (values) => {
  const body = {
    title: values.title,
    description: values.description,
    prompt: values.prompt,
    training_phrases: values.training_phrases,
    entities: values.entities,
    action: values.action,
  };

  if (values.intent_id) {
    const url = `/api/intent/${values.intent_id}/`;
    const response = await API('put', url, body);
    if (!response) {
      return false;
    }
    return response?.data;
  } else {
    const url = `/api/agent/${values.agent_id}/intent/`;
    const response = await API('post', url, body);
    if (!response) {
      return false;
    }
    return response?.data;
  }
};

export function useCreateIntent() {
  const {
    mutate: createIntentMutation,
    isLoading,
    error,
    data,
  } = useMutation((values) => createIntent(values));
  return {
    createIntentMutation,
    isLoading,
    error: (error && error?.data) || '',
    data,
  };
}
