import { Error, Label } from 'src/components/ui-components';
import React, { forwardRef } from 'react';
import { messages } from 'src/language';
import classNames from 'classnames';

const TextArea = forwardRef(
  ({ label, tooltip, required, className, error, rows, ...props }, ref) => {
    return (
      <div className="flex flex-col gap-1">
        {label && (
          <Label required={props.required} tooltip={tooltip}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}
        <div className="flex">
          <textarea
            rows={rows}
            style={{
              resize: 'none',
              color: 'black',
            }}
            className={classNames('form-control', className)}
            ref={ref}
            {...props}
          />
        </div>
        <div>
          {error && <Error message={error?.message || messages.VALIDATIONS.REQUIRED} />}
        </div>
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';
export default TextArea;
