import API from 'src/lib/api-client';
import { useQuery } from '@tanstack/react-query';
const fetchActions = async (params) => {
  const url = `/api/actions/?${params?.toString()}`;
  const response = await API('get', url);
  if (!response) {
    throw new Error('Failed to fetch actions');
  }

  return response?.data;
};

export function useActions(params) {
  const { data, error, isLoading, refetch } = useQuery(
    ['actions', params?.toString()],
    () => fetchActions(params),
  );

  return {
    data,
    error: (error && error?.message) || '',
    isLoading,
    refetch,
  };
}
