import useTitle from '../../../hooks/useTitle';
import { VALID_TEN_DIGIT_PHONE } from 'src/utils/regex';
import { useQueryClient } from '@tanstack/react-query';
import { Input } from '../../../components/ui-components';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import Avatar from 'src/components/ui-components/avatar';
// import HoverDiv from 'src/components/ui-components/hover-div';
// import useFileUploadHook from 'src/hooks/useFileUpload';
import { messages } from 'src/language';

import { useUpdateProfile } from './user.services';

function Profile() {
  useTitle('Profile', '', false);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth?.userDetails);
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    const { phone, name } = userDetails;
    reset({
      phone,
      name,
    });
  }, [reset, userDetails]);

  const {
    UpdateProfileMutation,
    isLoading,
    data,
    error,
    isSuccess,
    reset: resetAPIData,
  } = useUpdateProfile();

  useEffect(() => {
    if (data?.id) {
      queryClient.invalidateQueries({ queryKey: ['user', 'me'] });
      resetAPIData();
    }
  }, [data, queryClient, resetAPIData]);

  useEffect(() => {
    if (isSuccess) {
      toast.success('User profile updated successfully');
    }
  }, [isSuccess]);

  const onSubmit = (value) => {
    UpdateProfileMutation({
      ...value,
      phone: value.phone,
      name: value.name?.trim(),
    });
  };
  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="basic-form">
            <div className="form-valide">
              <div className="row">
                <div className="form-group mb-3 row">
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[A-Za-z0-9 ]{1,50}$/,
                        message: messages.VALIDATIONS.INVALID_USER_NAME,
                      },
                    }}
                    render={({ field: { name, onChange, value } }) => (
                      <Input
                        value={value}
                        placeholder="User Name"
                        label="User Name"
                        error={errors?.name}
                        onChange={(e) =>
                          setValue('name', e?.target?.value?.replace(/\s+/g, ' '))
                        }
                      />
                    )}
                  />
                </div>
                <div className="form-group mb-3 row">
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: VALID_TEN_DIGIT_PHONE,
                        message: 'Please enter a valid phone number',
                      },
                    }}
                    render={({ field: { name, value } }) => (
                      <Input
                        value={value ? value : ''}
                        placeholder="Phone Number"
                        label="Phone Number"
                        error={!value?.trim() ? errors?.phone : undefined}
                        onChange={(e) => setValue('phone', e.target.value)}
                      />
                    )}
                  />
                </div>
                <Input type="email" value={userDetails?.email} label="Email" disabled />
                <div className="form-group mb-3 row">
                  <div className="col-lg-8 d-flex justify-content-end  w-100">
                    <button
                      onClick={() => navigate(-1)}
                      type="button"
                      className="btn btn-outline-primary me-4"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit(onSubmit)}
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      Save
                      <span className="btn-icon-right ms-1">
                        <i className="fa fa-check"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
