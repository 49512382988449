import React, { useMemo } from 'react';

import Select from 'react-select';

const CustomClearText = () => 'clear all';
const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
});

export default function MultiSelectDropdown({ label, required, items, value, onChange }) {
  const memoizedItems = useMemo(() => {
    let options = items?.map((data) => {
      return { value: data.id, label: data.title };
    });
    return options;
  }, [items]);

  return (
    <div className="form-group mb-3">
      {label && (
        <label className="col-lg-4 col-form-label" htmlFor="val-username">
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <Select
        closeMenuOnSelect={false}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        isMulti
        value={value}
        options={memoizedItems}
        onChange={onChange}
        className="form-control p-0 pt-2"
        classNamePrefix="border-start-0 border-end-0 border-bottom-0 border-top-0 border-0"
      />
    </div>
  );
}
