import API from 'src/lib/api-client';
import { useMutation } from '@tanstack/react-query';

export const getAgentDetail = async (id) => {
  const url = `api/entities/${id}/`;
  const response = await API('get', url);
  if (!response) {
    return false;
  }
  return response?.data?.data;
};

const createEntity = async (values) => {
  const body = {
    title: values.title,
    description: values.description,
    data_type: values.data_type,
  };
  if (values.entityId != undefined) {
    const url = `/api/entities/${values.entityId}/`;
    const response = await API('put', url, body);
    if (!response) {
      return false;
    }
    return response?.data;
  } else {
    const url = '/api/entities/';
    const response = await API('post', url, body);
    if (!response) {
      return false;
    }
    return response?.data;
  }
};

export function useCreateEntity() {
  const {
    mutate: createEntityMutation,
    isLoading,
    error,
    data,
  } = useMutation((values) => createEntity(values));
  return {
    createEntityMutation,
    isLoading,
    error: (error && error?.data) || '',
    data,
  };
}
