import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useOnboardingDone from '../hooks/useOnboardingDone';

const AuthRoutes = [
  '/signup',
  '/login',
  '/forgot-password',
  '/reset-password',
  '/accept-invite',
  '/verify',
  '/public',
];

// const CommonRoutes = ['/profile', '/change-password', '/no-access'];
// const AdminRoutes = ['/dashboard'];
// const AgentRoutes = ['/intent/create', '/agents/create/'];

// const AllRoutes = [...AdminRoutes, ...AuthRoutes, ...CommonRoutes, ...AgentRoutes];

const checkForPathInRoutes = (pathname: string, routes: string[]) => {
  // Normalize the pathname by removing trailing slashes and ensuring it starts with a '/'
  const normalizedPathname = `/${pathname
    .split('?')[0]
    .split('#')[0]
    .replace(/^\/|\/+$/g, '')}`;

  // Check if the normalized pathname starts with any of the AuthRoutes
  return routes.some((route) => normalizedPathname.startsWith(route));
};

const isAuthRoute = (pathname: string): boolean => {
  return checkForPathInRoutes(pathname, AuthRoutes);
};

/**
 * Checks if the given user role and pathname combination indicates that a user
 * does not have access to a certain resource.
 *
 * @param {string} userRole - The role of the user.
 * @param {string} pathname - The pathname of the resource.
 * @return {boolean} True if the user does not have access, false otherwise.
 */

// const isNotFound = (pathname: string): boolean => {
//   return !checkForPathInRoutes(pathname, AllRoutes);
// };

/**
 * AuthRedirect is a higher order component that handles the redirection logic for authenticated users.
 * It checks the user's role and onboarding status to determine the appropriate redirection path.
 *
 * @returns {JSX.Element} - The appropriate JSX element to render based on the redirection logic.
 */
const AuthRedirect = () => {
  // Access the auth state from the Redux store
  const auth = useSelector((state: any) => state.auth);
  const onboardingDone = useOnboardingDone();
  const location = useLocation();

  // Redirect to '/login' if the user is not logged in and the current path is not an auth route
  if (!auth?.isLoggedIn && !isAuthRoute(location.pathname)) {
    return <Navigate to="/login" />;
  }

  // If the user is onboarded and at the root path, redirect to the publisher or company dashboard
  if (onboardingDone && location.pathname === '/') {
    // const RedirectPath = '/dashboard';
    const RedirectPath = '/agents/list';
    return <Navigate to={RedirectPath} />;
  }

  if (auth?.isLoggedIn && isAuthRoute(location.pathname)) return <Navigate to="/" />;
  // If the user is trying to access page for which they don't have enough permissions
  // if (!isNotFound(location.pathname)) {
  //   return <Navigate to="/no-access" />;
  // }
  // If the user is logged in and not a company or publisher, render the children routes
  return <Outlet />;
};

export default AuthRedirect;
