import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

export default function tableHeader({ table }) {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                className={classNames('px-2 dark:text-white text-sm h-16', {
                  'ps-6': index === 0,
                  'd-flex flex-row-reverse': index === headerGroup.headers.length - 1,
                })}
              >
                <strong>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </strong>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}
