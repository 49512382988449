import { configureStore } from '@reduxjs/toolkit';

import { DatastoreReducer, PermissionsReducer, TitleReducer } from '../reducers';
import { AuthReducer } from './Slices';

const reducer = {
  auth: AuthReducer,
  title: TitleReducer,
  permissions: PermissionsReducer,
  dataStore: DatastoreReducer,
};

export const store = configureStore({
  reducer,
  devTools: true,
});

export default store;
