import { Icons } from 'src/assets';
import { Error } from 'src/components/ui-components';
import { cva } from 'class-variance-authority';
import mergeRefs from 'merge-refs';
import React, { useRef } from 'react';
import { messages } from 'src/language';
import classNames from 'classnames';

const checkboxProps = cva('', {
  variants: {
    _size: {
      lg: 'h-6 w-6',
      md: 'h-5 w-5',
      sm: 'h-4 w-4',
    },
    defautlVariants: {
      _size: 'sm',
    },
  },
});

const Checkbox = React.forwardRef(
  (
    {
      label,
      name,
      checked,
      _size = 'sm',
      error,
      disabled,
      className,
      CustomIcon = Icons.CorrectIcon,
      ...props
    },
    ref,
  ) => {
    const localRef = useRef(null);
    return (
      <div className="flex flex-col gap-1">
        <div
          className={classNames('flex items-center gap-3', className, {
            'cursor-pointer': !disabled,
          })}
        >
          <div className="relative">
            <input
              type="checkbox"
              ref={mergeRefs(ref, localRef)}
              className={classNames(
                `absolute appearance-none w-full h-full cursor-pointer`,
                {
                  'cursor-not-allowed': disabled,
                },
              )}
              name={name}
              checked={checked}
              disabled={disabled}
              {...props}
            />
            {checked ? (
              <div
                className={classNames(
                  'bg-primary-500 rounded-xs flex items-center justify-center',
                  checkboxProps({ _size }),
                )}
              >
                {<CustomIcon className="stroke-white w-2 h-auto" />}
              </div>
            ) : (
              <div
                className={classNames(
                  'rounded-xs border-greyscale-400 border-[1px]',
                  checkboxProps({ _size }),
                  {
                    ' border-alert-error-base': error,
                  },
                )}
              />
            )}
          </div>

          {label && (
            <span
              role="checkbox"
              aria-checked={checked}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Space') {
                  if (localRef) localRef?.current?.click();
                }
              }}
              className="select-none dark:text-white text-sm"
              onClick={() => localRef && localRef?.current?.click()}
            >
              {label}
            </span>
          )}
        </div>
        {error && !checked && (
          <Error message={error?.message || messages.VALIDATIONS.REQUIRED} />
        )}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
