import { Icons } from 'src/assets';
import { Button } from 'src/components/ui-components';
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import Illustrations from 'src/assets/illustrations';
import { ENV } from 'src/constants';

const MAPPING = {
  [ENV.ROLES.COMPANY_ADMIN]: 'Company Admin',
  [ENV.ROLES.COMPANY_MANAGER]: 'Company Manager',
  [ENV.ROLES.FIELD_TEAM]: 'Field Technician',
  [ENV.ROLES.PUBLISHER_ADMIN]: 'Publisher Admin',
  [ENV.ROLES.PUBLISHER_USER]: 'Publisher User',
};

export default function NoAccessPage() {
  const auth = useSelector((state: any) => state.auth);
  const UserRole = auth?.user?.role?.alias;
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-full h-full items-center justify-center gap-8 relative">
      {/* <Illustrations.NoAccess /> */}
      <div className="flex flex-col items-center justify-center text-center gap-4">
        <h1>We are Sorry...</h1>
        <p className="pb-2 text-wrap w-3/4">
          {`Access is restricted to this page as this is being accessed by ${MAPPING[UserRole]}`}
        </p>
      </div>
      <Button
        variant="primary"
        size="lg"
        iconLeft={<Icons.HomeIcon />}
        onClick={() => {
          navigate('/');
        }}
      >
        Go to Home
      </Button>
      <LogoIcon className="w-36 h-auto pt-4 cursor-pointer stroke-black dark:stroke-white absolute right-10 bottom-10" />
    </div>
  );
}
