import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
const user = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : null;

const initialState = {
  isLoggedIn: user ? true : false,
  user,
  userDetails: {},
  isSessionExpired: false,
  isEnoughPermission: false,
  isSideMenuVisible: false,
};

const authSlice = {
  name: 'auth',
  initialState,
  reducers: {
    /* define your reducer functions here */
    setUser: (state, { payload }) => {
      if (payload?.logged_in_as) {
        state.user = payload?.logged_in_as;
        state.isLoggedIn = true;
        localStorage.setItem('user', JSON.stringify(payload?.logged_in_as));
        Cookies.set('access_token', payload?.access_token);
      }
    },
    setUserDetails: (state, { payload }) => {
      if (payload?.id) {
        const newUser = { ...payload };
        state.userDetails = newUser;
        state.isLoggedIn = true;
        localStorage.setItem('user_details', JSON.stringify(payload));
      }
    },
    updateUserDisplayMode: (state, { payload }) => {
      if (state?.user?.display_mode) {
        state.user.display_mode = payload;
      }
    },
    logout: () => ({
      isLoggedIn: false,
      user: null,
      isSessionExpired: false,
      isEnoughPermission: false,
    }),
    setSessionExpired: (state) => {
      state.isSessionExpired = true;
    },
    setEnoughPermission: (state) => {
      state.isEnoughPermission = !state.isEnoughPermission;
    },
    toggleSideMenu: (state) => {
      state.isSideMenuVisible = !state.isSideMenuVisible;
    },
  },
};

const { reducer, actions } = createSlice(authSlice);
export const {
  setUser,
  setUserDetails,
  updateUserDisplayMode,
  setSessionExpired,
  logout,
  setEnoughPermission,
  toggleSideMenu,
} = actions;
export default reducer;
