import { Icons } from '../assets';
import NotFoundLight from '../assets/images/404-light.webp';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-100 h-100 flex flex-col items-center">
      <div className="z-0 h-[90%]">
        <img
          src={NotFoundLight}
          alt="Not Found"
          className="dark:hidden block w-100 h-auto object-cover scale-100"
        />
      </div>
      <div className="z-10 d-flex w-100 align-items-center justify-content-center">
        <Button variant="primary" size="lg" onClick={() => navigate('/')}>
          <span>{<Icons.HomeIcon />} </span>
          Take me home
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
