import React from 'react';
import Skelton from '../index';

const AgenSkeletont = () => {
  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header">
          <div className="w-100 d-flex flex-row justify-content-end gap-3">
            <Skelton
              height={50}
              width={100}
              style={{ borderRadius: '4px', marginRight: '1rem' }}
            />
            <Skelton height={50} width={100} style={{ borderRadius: '4px' }} />
          </div>
        </div>
        <div className="card-body">
          <div className="basic-form">
            <div className="form-valide">
              <div className="row">
                <div className="col-xl-6 ">
                  {/* Name Input Skeleton */}
                  <div className="form-group mb-3 row ">
                    <Skelton height={50} width={400} style={{ borderRadius: '4px' }} />
                  </div>
                  {/* Description TextArea Skeleton */}
                  <div className="form-group mb-3 row">
                    <Skelton height={200} width={400} style={{ borderRadius: '4px' }} />
                  </div>
                </div>
                <div className="col-xl-6 ">
                  {/* Data Source Dropdown Skeleton */}
                  <div className="form-group mb-3 row">
                    <Skelton height={50} width={400} style={{ borderRadius: '4px' }} />
                  </div>
                  {/* Prompt TextArea Skeleton */}
                  <div className="form-group mb-3 row">
                    <Skelton height={200} width={400} style={{ borderRadius: '4px' }} />
                  </div>
                  {/* Buttons Skeleton */}
                  <div className="form-group mb-3 row">
                    <div className="col-lg-8 d-flex justify-content-end w-100 gap-3">
                      <Skelton
                        height={50}
                        width={100}
                        style={{ borderRadius: '4px', marginRight: '1rem' }}
                      />
                      <Skelton height={50} width={100} style={{ borderRadius: '4px' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgenSkeletont;
