/// Menu
import React, { useContext, useState } from 'react';
import { Collapse } from 'react-bootstrap';
/// Link
import { Link } from 'react-router-dom';
import { MenuList } from './Menu';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { ThemeContext } from '../../../store/theme.context';

const SideBar = () => {
  /// Open menu
  let d = new Date();
  const { iconHover, sidebarposition, headerposition, sidebarLayout, ChangeIconSidebar } =
    useContext(ThemeContext);

  const [state, setState] = useState('');
  let activeWindow = window.location.pathname.split('/')[1];

  let handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle('heart-blast');
  }
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
  );

  const handleMenuActive = () => {
    let activeId = window.location.pathname.split('/')[1];
    activeWindow = activeId;
  };
  // Menu dropdown list End

  return (
    <div className="deznav">
      <div className="deznav-scroll ">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            return (
              <li
                className={`${activeWindow === data.id ? 'mm-active' : ''}`}
                key={index}
              >
                <Link
                  to={data.to}
                  onClick={() => {
                    handleMenuActive(data.id);
                  }}
                  className="has-arrow"
                >
                  {data.iconStyle} <span className="nav-text">{data.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
        {/* <div className="copyright">
          <p>
            <strong>Jobie Admin Template</strong>© {d.getFullYear()} All Rights Reserved
          </p>
          <p>
            Made with <span className="heart" onClick={heartBlast}></span> by DexignZone
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default SideBar;
