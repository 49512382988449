import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const DARK_PREFIX = 'src/assets/logos/favicon-32x32.png';
const LIGHT_PREFIX = 'src/assets/logos/favicon-32x32.png';
const Favicon = () => {
  const [favicon, setFavicon] = useState<string>('');

  useEffect(() => {
    const setFaviconBasedOnPreference = () => {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const faviconSrc = prefersDarkMode ? DARK_PREFIX : LIGHT_PREFIX;
      setFavicon(faviconSrc);
    };

    setFaviconBasedOnPreference();
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', setFaviconBasedOnPreference);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', setFaviconBasedOnPreference);
    };
  }, []);

  return <Helmet>{favicon && <link rel="icon" href={favicon} />}</Helmet>;
};

export default Favicon;
