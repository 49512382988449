import classNames from 'classnames';
import { Icons } from '../../../assets';
import { useState } from 'react';
import DialogBox from 'src/components/ui-components/dialog';
import Button from 'src/components/ui-components/button';
import { Modal } from 'react-bootstrap';

const ConfirmPublishAgent = ({ show, agentId, onClose }) => {
  const Link = process.env.REACT_APP_CDN_LINK + `?agent_id=${agentId}`;
  const [copied, setCopied] = useState(false);
  const installationScript = `<script id="closeloopchatbot" src="${Link}"></script>`;
  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(installationScript);
  };
  return (
    <Modal className="fade" show={show} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>
          <h3>Get Chatbot Installation Code!</h3>
          <p className="lead">
            Copy the code snippet below and insert it into your website&apos;s footer or
            header before the closing head or body tag.
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          paddingInline: '2rem',
          fontSize: '20px',
        }}
      >
        <code
          style={{ maxWidth: '90%' }}
          className=" text-[crimson] bg-[#f1f1f1] p-1 rounded-xl"
        >
          {installationScript}
        </code>
        <button style={{ border: 0, background: 'transparent' }} onClick={handleCopy}>
          <Icons.CopyIcon stroke={copied ? '#4ade80' : 'black'} />
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmPublishAgent;
