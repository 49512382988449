import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from 'src/utils/common-utils';

export const columns = [
  {
    accessorFn: (row) => capitalizeFirstLetter(row?.name),
    id: 'name',
    size: 260,
    enableSorting: true,
    meta: { tooltip: true },
    header: () => <span>Name</span>,
    cell: (info) => {
      return (
        <Link
          className="cursor-pointer flex flex-row items-center gap-3"
          to={`/entities/update/${info.row.original.id}`}
        >
          <span className="text-ellipsis max-w-[50%] hover:underline text-primary-600 dark:text-primary-300">
            {info.row.original.title}
          </span>
        </Link>
      );
    },
  },
  {
    accessorFn: (row) => capitalizeFirstLetter(row?.name),
    id: 'name',
    size: 260,
    enableSorting: true,
    meta: { tooltip: true },
    header: () => <span>Created Date</span>,
    cell: (info) => {
      return (
        <span className="text-ellipsis max-w-[50%] text-primary-600 dark:text-primary-300">
          {new Date(info.row.original.created_at).toDateString()}
        </span>
      );
    },
  },
];

export const defaultState = {
  page: '1',
  per_page: '10',
};
export const DataTypes = [
  { title: 'string', id: 'string' },
  { title: 'integer', id: 'integer' },
  { title: 'boolean', id: 'boolean' },
  { title: 'date', id: 'date' },
  { title: 'time', id: 'time' },
];
