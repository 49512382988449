import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setHeader } from 'src/reducers/header';

export default function useTitle(
  title,
  subTitle,
  enableReturn = false,
  keepParentLayout = true,
) {
  const originalTitle = useRef | (null > document.title);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof document === 'undefined') return;

    const currentEl = originalTitle.current;
    document.title = title;
    dispatch(setHeader({ title, subTitle, keepParentLayout, enableReturn }));

    return () => {
      if (currentEl) {
        document.title = currentEl;
        dispatch(
          setHeader({
            title: '',
            subTitle: '',
            enableReturn: false,
            keepParentLayout: true,
          }),
        );
      }
    };
  }, [title, enableReturn, subTitle, keepParentLayout, dispatch]);
}
