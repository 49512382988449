import { Icons } from 'src/assets';
import { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import InfoButton from '../info-button';

const Input = forwardRef(
  (
    {
      type = 'text',
      label,
      error,
      className,
      required,
      disabled,
      onBlur,
      step = 1,
      containerClassName,
      btnText,
      btnClick,
      btnIcon,
      btnDisable,
      tooltip,
      ...props
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [inputActive, setInputActive] = useState(false);
    return (
      <div
        className={`form-group mb-3 ${
          props.value ? (error ? 'is-invalid' : 'is-valid') : ''
        },${containerClassName}`}
      >
        {label && (
          <div style={{ display: 'flex', gap: '6px' }}>
            <label className="col-form-label">
              {label}
              {required && <span className="text-danger">*</span>}
            </label>
            {tooltip && <InfoButton info={tooltip} />}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: type === 'password' ? '4px' : '0px',
            overflow: 'hidden',
          }}
          className={classNames('form-control')}
        >
          <input
            style={{ border: 0 }}
            type={showPassword ? 'text' : type}
            required={required}
            onFocus={() => setInputActive(true)}
            onBlur={(e) => {
              setInputActive(false);
              onBlur && onBlur(e);
            }}
            className={classNames('w-100', className, {
              'cursor-not-allowed ': disabled,
            })}
            ref={ref}
            disabled={disabled}
            step={step}
            {...props}
          />
          {type === 'password' &&
            (!showPassword ? (
              <Icons.EyeOnIcon onClick={() => setShowPassword((prev) => !prev)} />
            ) : (
              <Icons.EyeOffIcon onClick={() => setShowPassword((prev) => !prev)} />
            ))}
          {type === 'with_btn' && (
            <Button
              style={{ display: 'flex' }}
              onClick={btnClick}
              variant="primary"
              disabled={btnDisable}
            >
              {btnIcon && <span className="btn-icon-right me-2">{btnIcon}</span>}
              {btnText}
            </Button>
          )}
        </div>
        {error && (
          <div
            id="val-username1-error"
            className="invalid-feedback animated fadeInUp"
            style={{ display: 'block' }}
          >
            {error?.message || ''}
          </div>
        )}
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: 'block' }}
        />
      </div>
    );
  },
);

Input.displayName = 'Input';
export default Input;
