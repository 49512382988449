import Axios from 'axios';
import Cookies from 'js-cookie';
import { setEnoughPermission, setSessionExpired } from 'src/reducers/auth';

import { store } from './create-store';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
});

// A function that calls '/api/csrf-cookie' to set the CSRF cookies. The
// default is 'sanctum/csrf-cookie' but you can configure it to be anything.

axios.interceptors.request.use((config) => {
  // If http method is `post | put | delete` and XSRF-TOKEN cookie is
  // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
  // proceed with the initial response
  config.headers.Authorization = `Bearer ${Cookies.get('access_token')}`;
  return config;
});

export default async function API(method = 'get', url = '', data = {}, retryAttempt = 0) {
  const auth = store.getState()?.auth;

  try {
    const response = await axios[method](url, data);
    if (!response) {
      return false;
    }
    return await response;
  } catch (error: any) {
    if (error.response?.status === 401 && auth?.user?.id && !auth?.isSessionExpired) {
      store.dispatch(setSessionExpired({}));
    } else if (error.response?.status === 403 && !auth.isEnoughPermission) {
      store.dispatch(setEnoughPermission({}));
    } else if (error.response?.status === 419) {
      Cookies.remove('access_token');
      localStorage.removeItem('user');

      if (retryAttempt < 1) {
        return await API(method, url, data, 1);
      } else {
        throw error.response;
      }
    }
    throw error.response;
  }
}
