import { configureStore } from '@reduxjs/toolkit';

import {
  AuthReducer,
  DatastoreReducer,
  PermissionsReducer,
  TitleReducer,
} from '../reducers';

const reducer = {
  auth: AuthReducer,
  title: TitleReducer,
  permissions: PermissionsReducer,
  dataStore: DatastoreReducer,
};

export const store = configureStore({
  reducer,
  devTools: true,
});
