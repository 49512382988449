import API from 'src/lib/api-client';
import { useMutation } from '@tanstack/react-query';

export const getDatasourceList = async () => {
  const url = '/api/data-source/';
  const response = await API('get', url);
  if (!response) {
    return false;
  }
  return response?.data?.data;
};
export const getAgentDetail = async (id) => {
  const url = `api/agent/${id}/`;
  const response = await API('get', url);
  if (!response) {
    return false;
  }
  return response?.data?.data;
};
export const getIntentList = async (id) => {
  const url = `/api/agent/${id}/intent/`;
  try {
    const response = await API('get', url);
    if (!response) {
      return false;
    }
    return response?.data?.data;
  } catch (error) {
    return false;
  }
};
export const deleteIntent = async (id) => {
  const url = `api/intent/${id}/`;
  const response = await API('delete', url);
  if (!response) {
    return false;
  }
  return response?.data;
};

const createAgent = async (values) => {
  const body = {
    name: values.name,
    prompt: values.prompt,
    description: values.description,
    data_source: values.data_source,
  };
  if (values.agentId != '') {
    const url = `/api/agent/${values.agentId}/`;
    const response = await API('put', url, body);
    if (!response) {
      return false;
    }
    return response?.data?.data;
  } else {
    const url = '/api/agent/';
    const response = await API('post', url, body);
    if (!response) {
      return false;
    }
    return response?.data?.data;
  }
};

export function useCreateAgent() {
  const {
    mutate: createAgentMutation,
    isLoading,
    error,
    data,
  } = useMutation((values) => createAgent(values));
  return {
    createAgentMutation,
    isLoading,
    error: (error && error?.data) || '',
    data,
  };
}
