import { createContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import CreateAgent from './createagent';
export const CreateAgentContext = createContext();
export default function AgentCreate() {
  const [createIntent, setCreateIntent] = useState(false);
  const [agentId, setAgentId] = useState('');
  const [editIntentId, setEditIntentId] = useState('');
  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const handleAgentId = (id) => {
    setAgentId(id);
  };
  const handleEditIntent = (id) => {
    setEditIntentId(id);
  };
  const contextValue = {
    createIntent,
    agentId,
    handleAgentId,
    editIntentId,
    handleEditIntent,
    control,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    watch,
    errors,
  };
  return (
    <CreateAgentContext.Provider value={contextValue}>
      <CreateAgent />
    </CreateAgentContext.Provider>
  );
}
