import { ReactComponent as Info } from 'src/assets/icons/info-icon.svg';
import { messages } from 'src/language';

export default function Error({ message = messages.VALIDATIONS.REQUIRED }) {
  return (
    <div className="flex items-center gap-1 mt-[4px]">
      <Info />
      <p className="text-alert-error-base text-sm ">{message}</p>
    </div>
  );
}
