export const MenuList = [
  {
    title: 'Agents',
    classsChange: 'mm-collapse',
    iconStyle: <i className="fa-solid fa-headset"></i>,
    to: '/agents/list',
    id: 'agents',
  },
  {
    title: 'Entities',
    classsChange: 'mm-collapse',
    iconStyle: <i className="fa-solid fa-cube"></i>,
    to: '/entities',
    id: 'entities',
  },
  {
    title: 'Data Source',
    classsChange: 'mm-collapse',
    iconStyle: <i className="fa-solid fa-server"></i>,
    to: '/datasource',
    id: 'datasource',
  },
  {
    title: 'Conversation',
    classsChange: 'mm-collapse',
    iconStyle: <i className="fa-regular fa-comment-dots"></i>,
    to: '/conversation',
    id: 'conversation',
  },
  {
    title: 'Actions',
    classsChange: 'mm-collapse',
    iconStyle: <i className="fa-solid fa-bolt"></i>,
    to: '/actions',
    id: 'actions',
  },
];
