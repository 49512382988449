/**
 * Imports all files in a directory
 * @param path: string
 */
export const getImgUrl = (path: string): string => {
  return new URL(`/src/assets/${path}`, window.location.origin).href;
};

/**
 * Method to convert seconds to "MM:SS" format
 * @param s: number
 * @returns string
 */
export function convertSecondsToMinuteSecondFormat(s: number): string {
  const min = Math.floor(s / 60);
  const sec = s % 60;
  return `${min} : ${sec >= 10 ? sec : `0${sec}`}`;
}

export function convertSecondsToHourMinuteFormat(time: number | undefined): string {
  if (time === undefined) return '';

  // Convert time to hours and minutes
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);

  // Format and return the time string
  return `${hours}h:${minutes}m`;
}

export const formatNumberWithCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const formatNumberAsCurrencyUSD = (number: number, decimals = 2): string => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' }, // Billion
    { value: 1e12, symbol: 'T' }, // Trillion
    // Add more if needed
  ];

  const item = lookup
    .slice()
    .reverse()
    .find((item) => number >= item.value);
  if (item) {
    const num = number / item.value;
    const hasDecimalPlaces = num % 1 !== 0;
    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: hasDecimalPlaces ? decimals : 0,
      maximumFractionDigits: hasDecimalPlaces ? decimals : 0,
    }).format(num);

    return `${formattedNumber}${item.symbol}`;
  }

  // Fallback for very small numbers
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  }).format(number);
};

/*
Sort Array of object values
 * @param arr , keyName
*/
export const sortBy = (arr: any = [], keyName) =>
  arr?.length
    ? arr.sort((a, b) => {
        if (a[keyName] < b[keyName]) {
          return -1;
        }
        if (a[keyName] > b[keyName]) {
          return 1;
        }
        return 0;
      })
    : [];

export const createDropdownOption = ({
  arr = [],
  value = 'id',
  label = 'name',
  status = false,
}) => {
  let arrVal = [];
  if (status) {
    arrVal = arr?.filter((v) => v?.status || v);
  } else arrVal = arr;

  return arrVal?.length
    ? arrVal.map((v: any) => ({
        ...v,
        value: v[value],
        label: v[label] !== undefined ? String(v[label]) : '',
      }))
    : [];
};

/* Formatting the values for the dropdown
 * @param value
 */
export const DropdownFormating = (
  value: {
    id: number;
    name: string;
  }[],
) => {
  return value
    ?.map(({ id, name }) => ({ label: name, value: id }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
};

/**
 * Normalize a phone number
 * @param value: String
 */
export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

/**
 * DeNormalize a phone number
 * @param value: String
 */
export const stripPhoneNumberFormatting = (value: string) =>
  value ? value.replace(/[^\d]/g, '').slice(0, 10) : value;

/**
 * Normalize a phone number
 * @param arr: object array
 * @param n: length of chunks required
 */
export const splitArrayIntoChunks = (arr: any[], n: number) => {
  const chunks: any = [];
  for (let i = 0; i < arr?.length; i += n) {
    const slice: any[] = arr.slice(i, i + n);
    chunks.push(slice);
  }
  return chunks;
};

/**
 * Map Error Response from API
 * @param response
 * @returns
 */
export const mapAPIErrorResponse = (response: { errors: any[]; message: string }) => {
  if (response.errors) {
    const error: string[] = [];
    const errors = Object.values(response?.errors);
    errors.forEach((value: any) => {
      error.push(' ' + value.toString());
    });
    return error.toString();
  } else {
    return response.message;
  }
};

export const toCapitalizeString = (value: string) => {
  const titleCase = value
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

  return titleCase;
};

/**
 * Download file from a URL
 * @param uri
 * @param name
 */
export const downloadURI = (uri, name = '') => {
  const link = document.createElement('a');
  link.setAttribute('download', name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Convert number to string like (10k, 1M)
 * @param number
 * @param dataType
 * @param digits
 * @returns
 */
export const numberConverter = (number, dataType = '', digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
  ];
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return number >= item.value;
    });
  if (dataType === 'percentage') {
    return (item ? (number / item.value).toFixed(digits) : '0') + ' %';
  } else {
    return item ? '$' + (number / item.value).toFixed(digits) + item.symbol : '$0';
  }
};

/*
   Add % suffix if type is percentage
   Add $ prefix if type is currency
   * @param number
   */
export const addPrefixSuffix = (string: string, type: string) => {
  if (type === 'currency') return `$${string}`;
  if (type === 'percentage') return `${string}%`;

  return string;
};

/*
   Add K, M, T after numbers
   * @param number
   */
export const addSuffixToNumber = (number: number, decimalPrecision = 2) => {
  let formattedNumber = number.toString();
  if (number >= 1000000000000) {
    formattedNumber =
      formatNumberWithCommas(number / 1000000000000, decimalPrecision) + 'T';
  } else if (number >= 1000000000) {
    formattedNumber = formatNumberWithCommas(number / 1000000000, decimalPrecision) + 'B';
  } else if (number >= 1000000) {
    formattedNumber = formatNumberWithCommas(number / 1000000, decimalPrecision) + 'M';
  } else if (number >= 1000) {
    formattedNumber = formatNumberWithCommas(number / 1000, decimalPrecision) + 'K';
  }

  return formattedNumber;
};

/*
   Add commas in numbers
   * @param number
   */
export const formatNumberWithCommas = (number: number, decimalPrecision = 2) => {
  const hasDecimalPlaces = number % 1 !== 0;

  const formattedValue = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: hasDecimalPlaces ? decimalPrecision : 0,
    maximumFractionDigits: hasDecimalPlaces ? decimalPrecision : 0,
  }).format(number);

  return parseFloat(formattedValue);
};

export const extractValues = (data): (string | number)[] => {
  return data?.map((obj) => obj.value);
};

export function convertArrayToCSV(data: any[], field = 'name') {
  const names = data?.map((obj) => obj?.[field]); // Extract names into an array
  return names?.join(', '); // Join the names with commas
}

export function capitalizeFirstLetter(string: string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export function numberToString(v) {
  if (typeof v === 'number' && !isNaN(v)) {
    return v.toString();
  }
  return v;
}

export function parseJSON(jsonString: string) {
  try {
    const parsedJSON = JSON.parse(jsonString);
    return parsedJSON;
  } catch {
    return null;
  }
}
export const checkImageLandscape = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as 'string';

      img.onload = () => {
        const isLandscape =
          img.naturalWidth >= img.naturalHeight &&
          img.naturalWidth <= 1920 &&
          img.naturalHeight <= 1080;
        resolve(isLandscape);
      };
      img.onerror = (error) => {
        reject(error);
      };
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};
