const REFETCH_INTERVAL = 900000;

export const ENV = {
  REFETCH_INTERVAL,
  ALLOWED_PROMPT_LENGTH: 5000,
  ROLES: {
    FIELD_TEAM: 'field_team',
    COMPANY_ADMIN: 'company_admin',
    COMPANY_MANAGER: 'manager',
    PUBLISHER_ADMIN: 'publisher_admin',
    PUBLISHER_USER: 'publisher_user',
  },
};

export const MAPPING = {
  TRIGGER: {
    'call-complete': 'Call Complete',
    'job-complete': 'Job Complete',
    'recording-complete': 'Recording Complete',
  },
  TRIGGER_EVENT: {
    'equal-to-revenue': 'Revenue = $0',
    'greater-to-revenue': 'Revenue > $10,000',
    'call-unbooked': 'Call Unbooked',
    'call-booked': 'Call Booked',
    every: 'Every',
  },
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
