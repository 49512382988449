import { useQuery } from '@tanstack/react-query';
import { getUser } from 'src/services/user.service';

export default function useUser(init = true) {
  const {
    refetch: refetchUserData,
    isLoading: isLoadingUserData,
    data: userData,
  } = useQuery({
    queryKey: ['user', 'me'],
    queryFn: getUser,
    enabled: init,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return {
    refetchUserData,
    isLoadingUserData,
    userData,
  };
}
