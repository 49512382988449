import classNames from 'classnames';
import InfoButton from '../info-button';

export default function Label({ children, required = false, tooltip = '', className }) {
  return (
    <label
      className={classNames(
        'd-flex align-items-center capitalize text-sm font-medium gap-1 leading-6',
        className,
      )}
    >
      <span className="text-nowrap">
        {children} {required && <span className="text-alert-error-base">&nbsp;*</span>}
      </span>
      {tooltip && <InfoButton info={tooltip} />}
    </label>
  );
}
