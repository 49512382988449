import API from 'src/lib/api-client';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';

export const getUser = async () => {
  const url = '/api/user/profile/';
  const response = await API('get', url);
  return response?.data?.data;
};

const updateProfile = async (values) => {
  const url = '/api/user/update-profile';
  const response = await API('post', url, values);
  if (!response) {
    return false;
  }
  return await response.data.data;
};

export function useUpdateProfile() {
  const {
    mutate: updateProfileMutation,
    isLoading,
    error,
    data,
  } = useMutation((values) => updateProfile(values));
  return {
    updateProfileMutation,
    isLoading,
    error: (error && error?.data) || '',
    data,
  };
}

const Logout = async () => {
  localStorage.removeItem('user');
  Cookies.remove('access_token');
  return null;
};

export function useLogout() {
  const {
    mutate: LogoutMutation,
    error = {},
    isLoading,
    isSuccess,
  } = useMutation(() => Logout());
  return {
    LogoutMutation,
    isSuccess,
    isLoading,
    error: (error && error?.response?.data) || '',
  };
}
