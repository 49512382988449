import { useSelector } from 'react-redux';

const useOnboardingDone = () => {
  const auth = useSelector((state: any) => state.auth);

  if (auth && auth?.isLoggedIn) {
    if (auth?.user?.company?.is_payment_due) {
      return false;
    }
    if (auth?.user?.role?.alias === 'company_admin') {
      if (
        auth?.user?.company?.id === undefined ||
        !auth?.user?.company?.is_billing_connected ||
        !auth?.user?.company?.is_crm_connected
      ) {
        return false;
      } else {
        return true;
      }
    } else if (auth?.user?.role?.alias === 'publisher_admin') {
      if (auth?.user?.publisher?.id === undefined) return false;
      else return true;
    }
  }
  return true;
};

export default useOnboardingDone;
