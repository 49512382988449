import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateProfile } from '../services/user.service';

export const ThemeContext = createContext(undefined);
const initialState = {
  background: { value: 'light', label: 'Light' },
};
const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});
const ThemeContextProvider = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const themeFromApi = auth?.user?.display_mode;
  const [state, dispatch] = useReducer(reducer, initialState);
  const storedTheme = localStorage.getItem('cxc_theme');
  const [theme, setTheme] = useState(storedTheme || themeFromApi || 'light');
  const body = document.querySelector('body');
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  const changeBackground = (name) => {
    body.setAttribute('data-theme-version', name.value);
    dispatch({ background: name });
  };

  const { updateProfileMutation } = useUpdateProfile();

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('cxc_theme', newTheme);
    updateProfileMutation({ display_mode: newTheme, name: auth?.user?.name });
  };

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);
  useEffect(() => {
    const body = document.querySelector('body');
    let resizeWindow = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      window.innerWidth >= 768 && window.innerWidth < 1300
        ? body.setAttribute('data-sidebar-style', 'overlay')
        : window.innerWidth <= 768
        ? body.setAttribute('data-sidebar-style', 'overlay')
        : body.setAttribute('data-sidebar-style', 'full');
    };
    body.setAttribute('data-typography', 'poppins');
    body.setAttribute('data-theme-version', 'light');
    // body.setAttribute("data-theme-version", "dark");
    body.setAttribute('data-layout', 'vertical');
    body.setAttribute('data-nav-headerbg', 'color_3');
    body.setAttribute('data-headerbg', 'color_1');
    body.setAttribute('data-sidebar-style', 'full');
    body.setAttribute('data-sibebarbg', 'color_3');
    body.setAttribute('data-primary', 'color_1');
    body.setAttribute('data-sidebar-position', 'fixed');
    body.setAttribute('data-header-position', 'fixed');
    body.setAttribute('data-container', 'wide');
    body.setAttribute('direction', 'ltr');
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, body, windowWidth, windowHeight, changeBackground }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within ThemeContextProvider');
  }
  return context;
};

export default ThemeContextProvider;
