import useTitle from '../../../hooks/useTitle';
import { useQuery } from '@tanstack/react-query';
import { Input, TextArea } from '../../../components/ui-components';
import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import { getEntityList, getIntentDetail, useCreateIntent } from './service';
import { useActions } from 'src/pages/actions/actions.services/fetch.service';
import MultiSelectDropdown from '../../../components/ui-components/multiSelect-dropDown';
import DropdownBootstrap from '../../../components/ui-components/dropdown-Input';
import { useNavigate, useParams } from 'react-router-dom';
const CreateIntent = () => {
  useTitle('Create Intent');
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const { agentId, intentId } = useParams();
  const { createIntentMutation, data, isLoading } = useCreateIntent();
  const { data: actionList } = useActions();
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
    trigger,
    reset,
  } = useForm({
    defaultValues: { training_phrases: [], entities: [], action: [] },
  });

  const { data: intentData } = useQuery({
    queryKey: ['intentId', 'detail'],
    enabled: true,
    queryFn: () => getIntentDetail(intentId),
    retry: false,
  });
  const { data: entitiesList } = useQuery({
    queryKey: ['entities', 'entitiesList'],
    enabled: true,
    queryFn: () => getEntityList(),
    retry: false,
  });

  const onCancel = () => {
    navigate(-1);
  };
  const createIntent = (values) => {
    const body = {
      agent_id: agentId,
      title: values.title,
      description: values.description,
      prompt: values.prompt,
      training_phrases: values.training_phrases,
      intent_id: values.intent_id,
    };
    if (values.action) {
      body['action'] = values.action.value;
    }
    const entities = values.entities?.map((item) => item.value);
    body['entities'] = entities;
    createIntentMutation(body);
  };
  const onPharaseInput = () => {
    if (inputRef.current.value != '') {
      setValue('training_phrases', [
        ...watch('training_phrases'),
        inputRef.current.value,
      ]);
    }
    inputRef.current.value = '';
  };
  const onDeletePharase = (index) => {
    const newArr = getValues().training_phrases;
    newArr.splice(index, 1);
    setValue('training_phrases', newArr);
  };
  useEffect(() => {
    if (data) {
      if (data?.status) {
        toast.success(`${data?.message}`);
        onCancel();
      } else {
        toast.error(data?.message);
      }
    }
  }, [data]);
  useEffect(() => {
    if (intentId && intentData) {
      setValue('title', intentData.title);
      trigger('title');
      setValue('description', intentData.description);
      trigger('description');
      setValue('prompt', intentData.prompt);
      trigger('prompt');
      setValue('training_phrases', [...intentData.training_phrases]);
      setValue('intent_id', intentId);
      if (intentData.entities.length) {
        let defaultEntities = intentData.entities.map((data) => {
          return { value: data.id, label: data.title };
        });
        setValue('entities', [...defaultEntities]);
      }
      const actionID = intentData.action;
      const selectedAction = actionList?.data.find((item) => item.id == actionID);
      if (selectedAction) {
        setValue('action', { value: selectedAction.id, label: selectedAction.title });
      }
    } else {
      reset();
    }
  }, [actionList?.data, intentId, intentData, setValue, trigger]);

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="basic-form">
            <div className="form-valide">
              <div className="row">
                <div className="form-group mb-3 row">
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      validate: {
                        require: (value) => {
                          if (!value?.trim()) return 'Required';
                        },
                      },
                      required: true,
                    }}
                    render={({ field: { name, onChange, value } }) => (
                      <Input
                        name={name}
                        label="Title"
                        required
                        placeholder="Title"
                        onChange={onChange}
                        error={errors && !value?.trim() ? errors.title : undefined}
                        value={value ? value : ''}
                      />
                    )}
                  />
                </div>
                <div className="form-group mb-3 row">
                  <Controller
                    name="training_phrases"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { name, value } }) => (
                      <div className="flex flex-col gap-3">
                        <Input
                          name={name}
                          ref={inputRef}
                          type="with_btn"
                          label="Training Pharases"
                          // required
                          placeholder="Enter Pharases"
                          error={errors ? errors.training_phrases : undefined}
                          btnText="Add"
                          btnIcon={<i className="fa fa-plus" />}
                          btnClick={onPharaseInput}
                        />
                        <div
                          style={{
                            gap: '0.5rem',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {watch('training_phrases').map((item, index) => {
                            return (
                              <div
                                key={item}
                                style={{
                                  width: 'fit-content',
                                  gap: '0.5rem',
                                  justifyContent: 'space-between',
                                  display: 'flex',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                  paddingInline: '6px',
                                  paddingBlock: '4px',
                                }}
                                className="border-warning rounded-3 border "
                              >
                                <span>{item}</span>
                                <button
                                  className="btn btn-danger shadow btn-xs sharp "
                                  onClick={() => onDeletePharase(index)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="form-group mb-3 row">
                  <Controller
                    name="entities"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <MultiSelectDropdown
                        onChange={onChange}
                        value={value}
                        items={entitiesList}
                        label="Entity"
                        error={!!errors?.entities && !value}
                      />
                    )}
                  />
                </div>
                <div className="form-group mb-3 row">
                  <Controller
                    name="action"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <DropdownBootstrap
                        onChange={onChange}
                        value={value}
                        maxItems={1}
                        items={actionList?.data}
                        label="Action"
                        error={!!errors?.entities && !value}
                      />
                    )}
                  />
                </div>
                <div className="form-group mb-3 row">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <TextArea
                        name={name}
                        label="Description"
                        placeholder="Description"
                        rows={10}
                        value={value ? value : ''}
                        onChange={onChange}
                        error={errors && !value?.trim() ? errors.description : undefined}
                      />
                    )}
                  />
                </div>
                <div className="form-group mb-3 row">
                  <Controller
                    name="prompt"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        require: (value) => {
                          if (!value?.trim()) return 'Required';
                        },
                      },
                    }}
                    render={({ field: { name, onChange, value } }) => (
                      <TextArea
                        name={name}
                        label="Prompt"
                        placeholder="Write your prompt here..."
                        rows={10}
                        value={value ? value : ''}
                        onChange={onChange}
                        required
                        error={errors && !value?.trim() ? errors.description : undefined}
                      />
                    )}
                  />
                </div>
                <div className="form-group mb-3 row">
                  <div className="col-lg-8 d-flex justify-content-end  w-100">
                    <button
                      onClick={onCancel}
                      type="button"
                      className="btn btn-outline-primary me-4"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit(createIntent)}
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      Save
                      {isLoading ? (
                        // Show spinner while loading
                        <span
                          role="status"
                          className="spinner-border spinner-border-sm ms-1"
                        />
                      ) : (
                        // Show check icon when not loading
                        <span className="btn-icon-right ms-1">
                          <i className="fa fa-check" />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateIntent;
