import { Icons } from 'src/assets';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { CheckBox } from 'src/components/ui-components';
// import Pagination from '../pagination';
import classNames from 'classnames';
import { useState } from 'react';
import { ReactComponent as MinusIcon } from 'src/assets/icons/minus.svg';
import Illustrations from 'src/assets/illustrations';
import { useThemeContext } from 'src/store/theme.context';

import TableHeader from './header';
import { Nav, Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Table({
  columns,
  data,
  page = 1,
  total = 20,
  useCheckBox = false,
  checkAllFlag = false,
  isLoading,
  onhandleSelectRows = () => {
    // empty function
  },
  selectedRows = [],
  onChangePage = () => {
    // empty function
  },
  onChangeSort,
  limit = 10,
  emptyMessage = 'Rows',
  sort,
  className,
  tableClassName,
  showSubRows = false,
}) {
  const { theme } = useThemeContext();
  const [expanded, setExpanded] = useState({});
  const table = useReactTable({
    data,
    columns: useCheckBox
      ? [
          {
            accessorFn: (row) => row.id,
            id: 'id',
            enableSorting: false,
            meta: {
              tooltip: true,
            },
            header: () => (
              <CheckBox
                checked={
                  !!data?.length &&
                  (selectedRows?.length === data?.length || selectedRows?.length > 0)
                }
                onChange={() => {
                  const updatedSelectedRows = selectedRows?.length === 0 ? data : [];
                  onhandleSelectRows(updatedSelectedRows);
                }}
                {...(selectedRows?.length !== data?.length
                  ? { CustomIcon: MinusIcon }
                  : {})}
              />
            ),
            cell: (table) => (
              <div className="flex items-center w-fit h-fit">
                <CheckBox
                  checked={
                    (selectedRows.find((v) => v.id === table.row.original.id)
                      ? true
                      : false) || checkAllFlag
                  }
                  onChange={() => {
                    const find = selectedRows.find((v) => v.id === table.row.original.id);
                    const updatedSelectedRows = find
                      ? [...selectedRows.filter((v) => v.id !== table.row.original.id)]
                      : [...selectedRows, table.row.original];
                    onhandleSelectRows(updatedSelectedRows);
                  }}
                />
              </div>
            ),
            footer: (props) => props.column.id,
            size: 0,
          },
          ...columns, // Add the rest of the columns
        ]
      : columns,
    state: {
      expanded,
      sorting: sort,
    },
    getSubRows: (row) => showSubRows && row.subRows,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: onChangeSort,
    getCoreRowModel: getCoreRowModel(),

    debugTable: process.env.REACT_APP_ENV === 'local',
  });
  const active = 1;
  let items = [];

  for (let number = 1; number <= total; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  const pag = (gutter, variant, bg, circle) => (
    <Pagination
      size={'sm'}
      className={`mt-4  ${gutter ? 'pagination-gutter' : ''} ${
        variant && `pagination-${variant}`
      } ${!bg && 'no-bg'} ${circle && 'pagination-circle'}`}
    >
      <li className="page-item page-indicator">
        <Link className="page-link" to="#">
          <i className="la la-angle-left" />
        </Link>
      </li>
      {items}
      <li className="page-item page-indicator">
        <Link className="page-link" to="#">
          <i className="la la-angle-right" />
        </Link>
      </li>
    </Pagination>
  );
  const totalColumns = useCheckBox ? columns?.length + 1 : columns?.length;
  return (
    <>
      {table.getRowModel().rows?.length > 0 && (
        <TableHeader table={table} useCheckBox={useCheckBox} />
      )}
      <tbody>
        {table.getRowModel().rows?.length === 0 && !isLoading && (
          <tr>
            <td colSpan={totalColumns}>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Illustrations.NothingFound variant={theme} />
                <p>No {emptyMessage} Found</p>
              </div>
            </td>
          </tr>
        )}

        {table.getRowModel().rows.map((row) => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, index) => {
                const { tooltip = true, meta } = cell.column.columnDef;
                return (
                  <td
                    className={classNames('text-sm h-16', {
                      'ps-6': index === 0,
                    })}
                    key={cell.id}
                    style={{
                      maxWidth: cell.column.getSize(),
                    }}
                  >
                    {tooltip === true && meta?.tooltip !== false ? (
                      <span className="py-[2px] !line-clamp-1 !overflow-hidden !whitespace-nowrap">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </span>
                    ) : (
                      <span
                        className={classNames(
                          'py-[2px] !line-clamp-1 !overflow-hidden !whitespace-nowrap',
                        )}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </span>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      {/* {isLoading && (
        <div className="d-flex justify-content-center w-100 mt-2">
          <Icons.Loader className="fill-black w-5 h-5" />
        </div>
      )} */}
      {/* {!isLoading && (
        // <div className="py-5">
        //   <Pagination
        //     page={page}
        //     totalElements={total}
        //     elementsPerPage={limit}
        //     onPageChange={(page) => onChangePage(page)}
        //   />
        // </div>
        <Nav>{pag(true, 'primary', false, false)}</Nav>
      )} */}
    </>
  );
}
